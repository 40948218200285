
import {computed, defineComponent,} from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {Actions} from "@/store/enums/StoreEnums";
import store from "@/store";
import {libTools} from "@/core/appcore/libs/Lib_Tools";

import AddNew from "@/components/operations/transfers/transfers/AddNew.vue";

export default defineComponent({
    name: "operation-transfer-lists",
    components: {
        AddNew,
    },
    setup() {


        store.dispatch(Actions.TRANSFER_LIST, {});
        const dataTramsferList = computed(() => {
            return store.getters.transfersList;
        });

        const deleteTransfer = (ID) => {
            store.dispatch(Actions.TRANSFER_COMPANY_LIST, {});
            Swal.fire({
                title: 'Emin misiniz?',
                text: "Kaydi silmek işlemi geri alınamaz.",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: 'Kaydı Sil',
                cancelButtonText: `Vazgeç`,
                confirmButtonColor: '#9c1d1d',
                cancelButtonColor: '#3085d6',
            }).then(function (result) {
                if (result.isConfirmed) {
                    const payload1 = {
                        ID: ID,
                    }
                    store.dispatch(Actions.TRANSFER_DELETE, payload1)
                        .then(() => {
                            store.dispatch(Actions.TRANSFER_LIST, {});
                            Swal.fire(
                                'Silindi!',
                                'Kayit silme işlemi tamamlandı.',
                                'success'
                            )
                        }).catch(() => {
                        Swal.fire({
                            text: store.getters.getTransferCompanyErrors[0],
                            icon: "error",
                            buttonsStyling: false,
                            confirmButtonText: "Tekrar dene!",
                            customClass: {
                                confirmButton: "btn fw-bold btn-light-danger",
                            },
                        });
                    });

                }
            });
        };

        return {
            dataTramsferList, deleteTransfer, libTools,
        };
    },

});


import {defineComponent, onMounted, ref,} from "vue";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import {ErrorMessage, Field, Form} from "vee-validate";
import * as Yup from "yup";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import {hideModal} from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";

interface IFormVals {
    Title: string;
    Type: string;
    is_sale: string;
}

export default defineComponent({
    name: "operation-transfer-addnew",
    components: {
        Field,
        Form,
        ErrorMessage,
    },

    setup(props) {

        onMounted(() => {
            setCurrentPageBreadcrumbs("Transferler", ["Operasyon"]);
        });

        const submitButtonRef = ref<null | HTMLButtonElement>(null);
        const modalRef = ref<null | HTMLElement>(null);

        const formVals = ref<IFormVals>({
            Title: "",
            Type: "",
            is_sale: "",
        });

        const validationSchema = Yup.object().shape({
            Title: Yup.string().required("Transfer Adı girilmemiş"),
            Type: Yup.string().required("Transfer Tipi seçilmemiş"),
            is_sale: Yup.string().required("Durum seçilmemiş"),
        });

        const submit = () => {
            if (!submitButtonRef.value) {
                return;
            }

            const payload1 = {
                Title: formVals.value.Title,
                Type: formVals.value.Type,
                is_sale: formVals.value.is_sale,
            }


            store.dispatch(Actions.TRANSFER_INSERT, payload1)
                .then(() => {
                    store.dispatch(Actions.TRANSFER_LIST, {});
                    Swal.fire({
                        text: "Tebrikler. Başarılı bir şekilde ekleme işlemini tamamladınız.",
                        icon: "success",
                        buttonsStyling: false,
                        confirmButtonText: "Geri Dön!",
                        customClass: {
                            confirmButton: "btn fw-bold btn-light-primary",
                        },
                    }).then(function () {
                        submitButtonRef.value.disabled = false;
                        submitButtonRef.value.setAttribute("data-kt-indicator", "off");
                        hideModal(modalRef.value);
                    });
                }).catch(() => {
                    Swal.fire({
                        text: store.getters.getUserErrors[0],
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: "Tekrar dene!",
                        customClass: {
                            confirmButton: "btn fw-bold btn-light-danger",
                        },
                });
                submitButtonRef.value.disabled = false;
                submitButtonRef.value.setAttribute("data-kt-indicator", "off");
            });


        }

        return {
            formVals, validationSchema, submit,
            submitButtonRef, modalRef,
        };
    },

});

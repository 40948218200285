
import {defineComponent} from "vue";

import List from "@/components/operations/transfers/transfers/List.vue";

export default defineComponent({
    name: "opetation-tranfers",
    components: {
        List,
    },
    setup() {
        return {

        };
    },
});
